import React from "react";
import { Link } from 'gatsby';

type PropsType = {
	children?: React.ReactNode,
	href: string,
	className?: string,
	id?: string
}

const InternalLink = (
	{
		children,
		href,
		className,
		id
	}: PropsType
) => (
	<Link to={href} className={className} id={id}>
		{children}
	</Link>
);

export default InternalLink;
