import classNames from "classnames";
import React, { MouseEventHandler, useCallback, useMemo } from "react";
import { Modify } from "../../types";
import { ButtonPropsType } from "../Button";
import Styles from "./GameButton.module.scss";

type GameButtonPropsType = Modify<ButtonPropsType , {
	onClick?: (t?: any) => void
	style: GameButtonStyleType
	extraStyles?: string
}>

export enum GameButtonStyleType {
	SMALL,
	MIDDLE,
	BIG,
	UNSTAKE,
}

const getStyles = (style: GameButtonStyleType) => {
	switch (style) {
		case GameButtonStyleType.SMALL:
			return Styles.styleGameButtonSmall
		case GameButtonStyleType.MIDDLE:
			return Styles.styleGameButtonMiddle
		case GameButtonStyleType.UNSTAKE:
			return Styles.styleGameButtonUnstake
		case GameButtonStyleType.BIG:
		default:
			return Styles.styleGameButtonBig
	}
}

export const GameButton = ({ text, style, extraStyles, disabled = false, ready = true, onClick = () => undefined } : GameButtonPropsType) => {
	const cName = useMemo(() => classNames(
		getStyles(style),
		disabled ? Styles.styleGameButtonDisabled : Styles.styleGameButtonEnabled,
		{[Styles.styleBusy]: !ready},
		extraStyles ? extraStyles : {}
	), [style, disabled, ready]);

	const handleClick: MouseEventHandler = useCallback((e) => {
		if (!disabled && ready)
			onClick(e);
	}, [disabled, ready]);

	return (
		<div
			className={cName}
			onClick={handleClick}
			// disabled={disabled}
		>
			{text}
		</div>
	)
}
