import { observer } from "mobx-react";
import React from "react";
import { useApp } from "../App";
import Styles from "./UserNameView.module.scss";

const UserNameView = observer(() => {
	const app = useApp();

	return (
		<div className={Styles.account}>
			{app.store.userName}
		</div>
	);
});

export default UserNameView;
