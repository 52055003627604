import { navigate } from "gatsby";
import { L } from "../l10n/L10n";

export enum PagePath
{
	MAIN = "/",
	CARDS = "/cards",
	BOOKS = "/books",
	PACKS = "/packs",
	HEROES = "/heroes",
	SPECIAL_NFT = "/special-edition",
	UNPACKING = "/unpacking",

	MANAENCHANTING = "/mana_enchanting",
	GAME_HOME = "/game/home",
	GAME_CARDS = "/game/cards",
	GAME_MARKET = "/game/market",
}

export async function navigateTo(pagePath: PagePath)
{
	await navigate(pagePath);
}

export function makePageTitle(title: string): string
{
	return title + " | " + L.siteName;
}

export const getCurrentPage = (): PagePath | undefined => {
	const url = typeof window !== 'undefined' ? window.location.href : '';

	return Object.keys(PagePath).find((page) => url.endsWith(page)) as PagePath
}