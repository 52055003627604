import { observer } from "mobx-react";
import React from "react";
import { ualService } from "../../App";
import { L } from "../../l10n/L10n";
import { GameButton, GameButtonStyleType } from "../game/GameButton";

const LogoutBtn = observer((
	{
		onClick,
	}: {
		onClick?: () => void
	},
) => {
	const onBtnClick = () => {
		ualService.logout();

		if (onClick)
			onClick();
	};

	return (
		<GameButton
			text={L.menu.logout}
			style={GameButtonStyleType.SMALL}
			onClick={() => onBtnClick()}
		/>
	);
});

export default LogoutBtn;