import { observer } from "mobx-react";
import React from "react";
import { ualService, useApp } from "../App";
import { GameButton, GameButtonStyleType } from "./game/GameButton";

const LoginBtn = observer((
	{
		style = GameButtonStyleType.BIG,
	}: {
		style?: GameButtonStyleType,
	}) =>
{
	const app = useApp();

	const onLoginClick = async () => {
		try {
			await ualService.login();
		} catch (e) {
			console.error(e);
			app.showError(e.message ?? e);
		}
	};

	return (
		<GameButton
			text={"Login"}
			style={style}
			onClick={() => onLoginClick()}
		/>
	);
});

export default LoginBtn;
